.clientsContainer {
  padding: 40px 20px;
  background: #fff;
}
.clientsContainer header h3 {
  font-family: "Itim", cursive;
  font-size: 24px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.clientsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 100px;
}

@media screen and (min-width: 768px) {
  .clientsContainer {
    padding: 80px 120px;
  }
  .clientsContainer header h3 {
    font-size: 48px;
    text-align: center;
    margin-bottom: 70px;
  }
}
