.footerContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 18px 150px 18px 40px;
}
.companyName {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  font-family: "DM Sans", sans-serif;
}
.socialLinksContainer {
  display: flex;
  justify-content: flex-end;
}
.socialLinksContainer ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  width: 20%;
}
.logoContainer {
  height: 68px;
  width: 252px;
}

a {
  color: #000;
  font-size: 18px;
}

@media screen and (min-width: 280px) and (max-width: 1300px) {
  .footerContainer {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 20px;
  }
  .logoContainer {
    justify-self: center;
  }
  .companyName {
    font-size: 1em;
    margin-bottom: 20px;
  }
  .socialLinksContainer {
    justify-content: center;
  }
}
