.aboutContainer {
  /* display: grid; */
  /* grid-template-columns: 60% 40%; */
  padding: 20px;
  /* align-items: center; */
  background: rgba(91, 97, 246, 1);
}

.aboutContainerText {
  display: grid;
  grid-template-columns: 100%;
}
.aboutContainerText h2 {
  line-height: 57px;
  font-size: 24px;
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 1);
  font-family: "Itim", cursive;
  font-weight: 400;
}
.aboutContainerText h2 span {
  color: rgba(172, 175, 255, 1);
}
.aboutContainerText p {
  font-size: 16px;
  line-height: 27px;
  width: 100%;
  color: rgba(255, 255, 255, 1);
  font-family: "DM Sans", sans-serif;
}
.aboutHero {
  padding-top: 65px;
}

@media screen and (min-width: 768px) {
  .aboutContainer {
    padding: 40px 120px;
  }
  .aboutContainerText {
    grid-template-columns: 40% 60%;
    width: 100%;
  }
  .aboutContainerText h2 {
    font-size: 48px;
    width: 361px;
    padding-bottom: 0;
  }

  .aboutContainerText p {
    font-size: 18px;
    width: 767px;
  }
  .aboutHero {
    width: 100%;
    padding-top: 10px;
  }
  .aboutHero img {
    width: 100%;
  }
}
