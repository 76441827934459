.contactsContainer {
  /* padding: 20px; */
  background: #eff0ff;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
}
.contactHeader {
  background-color: rgba(0, 3, 86, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.contactHeader h2 {
  color: rgba(255, 247, 247, 1);
  font-family: "Itim", cursive;
  line-height: 55px;
  font-size: 24px;
  font-weight: 400;
}
.contactHeader span {
  color: rgba(155, 159, 255, 1);
}
form {
  display: flex;
  flex-direction: column;
  background: rgba(91, 97, 246, 1);
  padding: 20px;
}
.inputContainer {
  margin-bottom: 30px;
}
.inputContainer input {
  border-radius: 5px;
  border: none;
  background-color: rgba(135, 140, 255, 1);
  height: 40px;
  width: 100%;
  outline: none;
}
.inputContainer textarea {
  border-radius: 5px;
  border: none;
  background-color: rgba(135, 140, 255, 1);
  height: 100px;
  outline: none;
  width: 100%;
}
::placeholder {
  color: rgba(204, 206, 255, 1);
  font-family: "DM Sans", sans-serif;
}

.sendButton button {
  background-color: rgba(255, 255, 255, 1);
  border: none;
  color: rgba(91, 97, 246, 1);
  padding: 12px 30px;
  border-radius: 8px;
  font-family: "DM Sans", sans-serif;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .contactsContainer {
    /* padding: 100px 150px; */
    grid-template-columns: repeat(2, 1fr);
  }
  .contactHeader {
    font-size: 48px;
  }
  .contactHeader h2 {
    line-height: 55px;
    font-size: 58px;
    max-width: 506px;
  }
  form {
    padding: 40px 200px 40px 100px;
  }
}
