.heroContainer {
  display: grid;
  grid-template-columns: 100%;
  padding: 40px 20px;
}
.heroContainerText h2 {
  font-family: "Itim", cursive;
  color: #000;
  font-size: 32px;
  font-weight: 400;
  line-height: 76px;
}
.heroContainerText h2 span {
  color: #5b61f6;
}

.heroContainerText p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}

.heroImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
}
.heroImageGradient {
  background: linear-gradient(90deg, rgba(240, 240, 255, 1) 70%, rgba(196, 198, 255, 1) 100%);
  border-radius: 50%;
  height: 316px;
  width: 316px;
  position: relative;
  z-index: -1;
  /* overflow-x: hidden; */
}
.heroImageGradient img {
  position: absolute;
  width: 100%;
  height: 480px;
  object-fit: cover;
  top: -70px;
  object-position: inherit;
}

@media screen and (min-width: 768px) {
  .heroContainer {
    grid-template-columns: 50% 50%;
    padding: 40px 120px;
  }
  .heroContainerText {
    max-width: 516px;
  }
  .heroContainerText h2 {
    font-size: 64px;
  }

  .heroImage {
    margin: 0px;
  }

  .heroImageGradient img {
    top: -100px;
  }
}
