.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.normalClass {
  font-family: "Itim", cursive;
  cursor: pointer;
  font-size: 1em;
  margin-right: 35px;
}
.activeClass {
  font-family: "Itim", cursive;
  border-bottom: 2px solid grey;
  border-color: #000;
  border-radius: 1px;
  cursor: pointer;
  font-size: 1em;
  margin-right: 35px;
}
.logoContainer {
  width: 100px !important;
  height: 50px !important;
}
.logoContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navLinksContainer {
  display: flex;
  gap: 20px;
  order: 3;
  margin: 0 auto;
  padding: 16px 0 0 0;
}
.navContactBtn {
  order: 2;
}
.navContactBtn button {
  width: 100px;
  height: 36px;
  padding: 15px 30px 15px 30px;
  border-radius: 100px;
  gap: 10px;
  background: #5b61f6;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .navContainer {
    padding: 10px 120px;
    grid-template-columns: repeat(1, 1fr);
    box-shadow: none;
  }
  .logoContainer {
    width: 150px !important;
    height: 70px !important;
  }

  .navLinksContainer {
    justify-content: space-between;
    order: 2;
    padding: 0;
  }
  .navContactBtn {
    order: 3;
  }

  .normalClass {
    margin-right: 0;
    font-size: 1em;
  }
  .activeClass {
    margin-right: 0;
    font-size: 1em;
  }
}
