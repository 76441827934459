body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p,
li,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.responsiveContainer {
  width: 100vw;
  /* max-width: 100%;
  min-width: 100%; */
}

@media screen and (min-width: 1024px) {
  .responsiveContainer {
    max-width: 1440px;
    min-width: 1440px;
    width: 1440px;
    margin: auto;
    height: 100%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz@0,9..40;1,9..40&display=swap");
