.everyoneContainer {
  background: rgba(240, 241, 255, 1);
  padding: 20px;
}
.everyoneContainer header h3 {
  font-family: "Itim", cursive;
  font-size: 24px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.everyoneGrid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.everyoneCardContainer {
  border-radius: 20px;
  padding: 20px;
  display: flex;
  background-color: #fff;
  margin-bottom: 40px;
  gap: 20px;
  /* width: 100%; */
}

.ml70 {
  margin-left: 0px;
}
.ml150 {
  margin-left: 0px;
}
.ml100 {
  margin-left: 0px;
}

.everyoneCardContainer h4 {
  font-family: Itim;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Itim", cursive;
  margin-bottom: 16px;
}
.everyoneCardContainer p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.everyoneContainer img {
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .everyoneContainer {
    padding: 80px 120px;
  }
  .everyoneCardContainer {
    width: 600px;
  }
  .everyoneContainer header h3 {
    font-size: 48px;
    margin-bottom: 70px;
    text-align: center;
  }

  .ml70 {
    margin-left: 70px;
  }
  .ml150 {
    margin-left: 150px;
  }
  .ml100 {
    margin-left: 100px;
  }
  .everyoneContainer img {
    margin-bottom: 0;
  }
}
