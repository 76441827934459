.alertContainer {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 300px;
  height: 60px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  z-index: 15;
}
.alertContainer p {
  font-family: "MatteoRegular", sans-serif;
  font-size: 1rem;
  margin-left: 10px;
  color: #fff;
}
