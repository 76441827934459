.servicesContainer {
  background: #fff;
  padding: 40px 20px;
}
.servicesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust column width as needed */
  grid-gap: 40px 80px;
}

.servicesHeader {
  padding-bottom: 40px;
  font-size: 28px;
  color: rgba(51, 51, 51, 1);
  font-family: "Itim", cursive;
  font-weight: 400;
}

.serviceCardImgContainer {
  width: 58.17px;
  height: 55.88px;
  /* align-self: center; */
}
.serviceCardImgContainer img {
  width: 100%;
  height: 100%;
}
.serviceCardContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.serviceCardTextContainer p {
  font-size: 1rem;
  line-height: 26px;
  color: #4f4f4f;
  font-family: "Inter", sans-serif;
  flex-grow: 1; /* Ensure the paragraph takes remaining space */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for text overflow */
  margin: 0; /* Remove default margin */
}
.serviceCardTitleContainer h4 {
  font-size: 1.2rem;
  line-height: 25px;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-family: "Itim", cursive;
}

.listSection {
  width: 1100px;
  min-height: 285px;
  margin: 100px auto 50px auto;
  border-radius: 30px;
  /* background: linear-gradient(180deg, rgba(239, 239, 255, 1) 100%, rgba(145, 149, 249, 0.67) 66.81%, rgba(165, 168, 250, 0.55) 55%, rgba(91, 97, 246, 1) 100%); */
  display: flex;
  position: relative;
  padding: 40px;
  background: linear-gradient(100.14deg, #efefff 19.47%, rgba(145, 149, 249, 0.668085) 51.39%, rgba(165, 168, 250, 0.55) 62.75%, #5b61f6 80.86%);
}
.listSection article {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 400;
  font-family: "Itim", cursive;
}
.listSection article h5 {
  font-size: 27px;
  line-height: 32px;
  color: #000;
}
.listSection article p {
  font-size: 27px;
  line-height: 32px;
  color: #333333;
}
.listSection article img {
  width: 70px;
  height: 70px;
}

.handImageContainer img {
  position: absolute;
  top: -90px;
  right: -100px;
}

@media screen and (min-width: 768px) {
  .servicesContainer {
    padding: 98px 120px;
  }
  .listSection article p {
    width: 555px;
  }
  .servicesHeader {
    font-size: 48px;
    padding-bottom: 100px;
  }

  .servicesCardContainer {
  }
}
